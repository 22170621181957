/* You can add global styles to this file, and also import other style files */
@import "https://unpkg.com/open-props";
html,body {
    font-size: 16px;
    padding: 0;
    margin: 0;
    height: 100%;
    overflow-x: hidden;
    /* display: flex;
    flex-direction: column; */
    /* justify-content: center; */
     /* align-items: center;  */
    /* align-content: center; */
}
h1,h2,h3,h4,h5,h6 {
    font-family: 'Roboto Condensed', sans-serif;
    padding: 0;
    margin: 0;
}
p,li {
    font-family: 'Alegreya Sans', sans-serif;
    padding: 0;
    margin: 0;
}